import http from "@/utils/http/index.js";

export default {
  dataSourceList: (param) => {
    return http.$p("/data-source/simple-list", param);
  },
  fields: (param) => {
    return http.$p("/dp/explore/fields", param);
  },
  dataset: (param) => {
    return http.$p("/dp/explore/dataset", param);
  },
  export: (param) => {
    return http.$pBlob("/dp/explore/export", param);
  },
};
