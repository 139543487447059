import http from "@/utils/http/index.js";

export default {
  getConfig: (param) => {
    return http.$p("/access-control/get-config", param);
  },
  setConfig: (param) => {
    return http.$p("/access-control/set-config", param);
  },

  dpReportAssignedUsers: (param) => {
    return http.$p("/access-control/dp-report-assigned-users", param);
  },
};
