import http from "@/utils/http/index.js";

export default {
  dpReportList: (param) => {
    return http.$p("/dp/report/list", param);
  },
  dpReportDetail: (param) => {
    return http.$p("/dp/report/detail", param);
  },
  dpReportModify: (param) => {
    return http.$p("/dp/report/modify", param);
  },
  dpReportDelete: (param) => {
    return http.$p("/dp/report/delete", param);
  },
  dpInstantReportAdd: (param) => {
    return http.$p("/dp/report/add-instant-report", param);
  },
};
