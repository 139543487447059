// import * as dayjs from "dayjs";
export default {
  namespaced: true,
  state: () => ({
    // {
    //   "companyId": "1000000",
    //   "username": "admin@mape.ai",
    //   "name": "Admin",
    //   "tokenName": "token",
    //   "tokenValue": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJtYXBlIzEwMDAwIiwicm5TdHIiOiJZYkt4R1RMRUR1cEpHSlBSYk1qRzBZSUFjTjdoTnV0ZyJ9.oPGCTKpVl1jSKLcn6rYU7ih40xPmz87MDg4C_T8JZUE",
    //   "tokenTimeoutSeconds": 2591999
    // }
    info: {},

    // {
    //   "id": "12345000",
    //   "tenantKey": "mape",
    //   "name": "mApe",
    //   "logoUrl": "",
    //   "companyPermKeys": ["user.delete", "user.changeStatus", "user.resetPassword", "user.detail",…]
    //   "userPermKeys": ["user.delete", "user.changeStatus", "user.resetPassword", "user.detail",…]
    // }
    companyInfo: {},

    // {
    //   "modelName": "Test 2 Model",
    //   "crossSectionName": "Global",
    //   "dependentVariable": "Unit Sales 250ml",
    //   "modelStartDate": "2019-12-30",
    //   "modelEndDate": "2022-12-26",
    //   "frcstEndDate": "2023-06-26",
    //   "depVarUnit": "#",
    //   "periodOfData": "Weekly",
    //   "valuePerUnit": 8,
    //   "profitMargin": 30
    // }
    selectedModel: null,

    // {
    //   dateType:"daily",
    //   shortcutItem:"0",
    //   curDate:["2020-01-01","2024-01-01"]
    // }
    selectDate: null,

    //[
    //['key1', 'value1'],
    //['key2', 'value2'],
    //['key3', 'value3']
    //]
    historyRadio: {},

    lastVisitFullPath: null,
    //viewReportInfo: {},
  }),
  mutations: {
    login(state, payload) {
      state.info = payload;
    },
    logout(state) {
      state.info = {};
      state.companyInfo = {};
      state.selectedModel = null;
      state.selectDate = null;
      state.historyRadio = {};
      // state.viewReportInfo = {};
    },
    setTenantInfo(state, companyInfo) {
      state.companyInfo = companyInfo;
    },
    setModelStats(state, payload) {
      if (payload) {
        state.selectedModel = payload;
      }
    },
    setSelectDate(state, selectDate) {
      if (selectDate) {
        state.selectDate = selectDate;
      }
    },
    setHistoryRadio(state, payload) {
      if (payload) {
        state.historyRadio = Object.assign(state.historyRadio, payload);
      }
    },
    setLastVisitFullPath(state, lastVisitFullPath) {
      state.lastVisitFullPath = lastVisitFullPath;
    },
    // setViewReportInfo(state, viewReportInfo) {
    //   state.viewReportInfo = viewReportInfo;
    // },
  },
  actions: {
    login(context, payload) {
      context.commit("login", payload);
    },
    logout(context) {
      context.commit("logout");
    },
    updateTenantInfo({ commit }, companyInfo) {
      commit("setTenantInfo", companyInfo);
    },
    updateSelectedModel({ commit }, selectedModel) {
      commit("setModelStats", selectedModel);
    },
    updateSelectDate({ commit }, selectDate) {
      commit("setSelectDate", selectDate);
    },
    updateHistoryRadio({ commit }, payload) {
      commit("setHistoryRadio", payload);
    },
    updateLastVisitFullPath({ commit }, lastVisitFullPath) {
      commit("setLastVisitFullPath", lastVisitFullPath);
    },
    // updateViewReportInfo({ commit }, viewReportInfo) {
    //   commit("setViewReportInfo", viewReportInfo);
    // },
  },
  getters: {
    userInfo: (state) => state.info,
    companyInfo: (state) => state.companyInfo,
    modelInfo: (state) => state.selectedModel,
    selectDate: (state) => state.selectDate,
    historyRadioVal: (state) => (key) => {
      return state.historyRadio[key];
    },
    lastVisitFullPath: (state) => state.lastVisitFullPath,
    // viewReportInfo: (state) => state.viewReportInfo,
  },
};
