import http from "@/utils/http/index.js";

export default {
  subscribe: (param) => {
    return http.$p("/landing/subscribe", param);
  },
  trialActivation: (param) => {
    return http.$p("/landing/trial-activation", param);
  },
};
