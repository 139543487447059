/**
 * app：公共国标化
 * 原则上按页面分类，对象名和各面name同名
 */
export default {
  app: {
    home: "网站首页",
    prediction: "赛事预测",
    dataAnalysis: "数据分析",
    login: "登录",
    joinUs: "成为会员",
    trial: "试用",
  },
  homeView: {
    declaration: "本網站及其應用程式所有內容純屬資訊性質，並不涉及任何金錢賭博，亦不得作為此等意見的依據。詳情請參閱使用",
    rules: "使用條款及細則",
    privacyPolicy: "隱私政策聲明",
    companyName: "氪騰科技有限公司",
  },
  predictionView: {},
  dataAnalysisView: {},
};
