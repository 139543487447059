import http from "@/utils/http/index.js";
//import axios from "axios";

export default {
  modelNameList: () => {
    return http.$p("/report/vrpt/model-name-list", {});
  },
  modelNameListOpr: (param) => {
    return http.$p("/report/vrpt/opr/model-name-list", param);
  },

  getFitReport: (VrptModelReqDto) => {
    return http.$p("/report/vrpt/fit-report", VrptModelReqDto);
  },

  fitMonthlyReport: (param) => {
    return http.$p("/report/vrpt/fit-monthly-report", param);
  },

  decomMonthlyReport: (param) => {
    return http.$p("/report/vrpt/decom-monthly-report", param);
  },

  sumData: (param) => {
    return http.$p("/report/vrpt/sum-contrib-report", param);
  },

  sumMonthlyData: (param) => {
    return http.$p("/report/vrpt/sum-contrib-monthly-report", param);
  },

  statsData: (param) => {
    return http.$p("/report/vrpt/stats-report", param);
  },

  decomData: (param) => {
    return http.$p("/report/vrpt/decom-report", param);
  },

  roiData: (param) => {
    return http.$p("/report/vrpt/roi-breakdown", param);
  },

  roiActList: (param) => {
    return http.$p("/report/vrpt/items-roi-act-list", param);
  },

  roiCtgNameList: (param) => {
    return http.$p("/report/vrpt/items-roi-ctg-name-list", param);
  },

  roiCtgTypeList: (param) => {
    return http.$p("/report/vrpt/items-roi-ctg-type-list", param);
  },

  itemRoiReportList: (param) => {
    return http.$p("/report/vrpt/items-roi-report-list", param);
  },

  roiOvertimeReportDescCondition: (param) => {
    return http.$p("/report/vrpt/roi-ot-repo/desc-condition", param);
  },

  roiOvertimeReportChart: (param) => {
    return http.$p("/report/vrpt/roi-ot-repo/chart", param);
  },

  roiOvertimeReportChartMonthly: (param) => {
    return http.$p("/report/vrpt/roi-ot-repo/chart-monthly", param);
  },

  itemsResponseList: (param) => {
    return http.$p("/report/vrpt/items-response-list", param);
  },

  responseList: (param) => {
    return http.$p("/report/vrpt/response-list", param);
  },

  plData: (param) => {
    return http.$p("/report/vrpt/pl-overtime-report", param);
  },

  plMonthlyData: (param) => {
    return http.$p("/report/vrpt/pl-overtime-monthly-report", param);
  },

  performance1CardData: (param) => {
    return http.$p("/report/performance/cpasGsa", param);
  },

  performance1ChartData: (param) => {
    return http.$p("/report/performance/chart", param);
  },

  performance1TableData: (param) => {
    return http.$p("/report/performance/total", param);
  },

  performanceAdsFieldList: (param) => {
    return http.$p("/report/performance/ads-field-list", param);
  },

  performanceChannelList: () => {
    return http.$p("/report/performance/media-channel-list", {});
  },

  performanceChannelAdsFieldList: (param) => {
    return http.$p("/report/performance/media-channel-ads-field-list", param);
  },

  performance2MetricData: (param) => {
    return http.$p("/report/performance/chart-group-date", param);
  },

  performance3MetricData: (param) => {
    return http.$p("/report/performance/chart-data-total", param);
  },

  performanceChannelPeriodPercent: (param) => {
    return http.$p("/report/performance/channel-period-percent", param);
  },

  reportSettings: (param) => {
    return http.$p("/report/performance/report-settings", param);
  },

  reportMetrics: (param) => {
    return http.$p("/report/performance/report-metrics", param);
  },

  reportMetricsChart: (param) => {
    return http.$p("/report/performance/report-metrics-chart", param);
  },

  tableMetrics: (param) => {
    return http.$p("/report/performance/table-metrics", param);
  },

  viewReports: () => {
    return http.$p("/report/performance/view-reports", {});
  },

  pieMetrics: (param) => {
    return http.$p("/report/performance/pie-metrics", param);
  },
};
