import http from "@/utils/http/index.js";
import store from "@/store";
export default {
  login: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/operator/login", param)
        .then((res) => {
          if (res && res.code === "IAAO-0000") {
            store.dispatch("user/login", res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      http
        .$p("/operator/logout", {})
        .then((res) => {
          store.dispatch("user/logout");
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  listCompanies: () => {
    return http.$p("/operator/list-companies", {});
  },
  loginCompany: (param) => {
    return http.$p("/operator/login-company", param);
  },
  info: () => {
    return http.$p("/operator/info", {});
  },
  modPw: (param) => {
    return http.$p("/operator/mod-pw", param);
  },
  modInfo: (param) => {
    return http.$p("/operator/mod-info", param);
  },
};
