import http from "@/utils/http/index.js";

export default {
  userPage: (param) => {
    return http.$p("/mgmt/user/page", param);
  },
  userDetail: (param) => {
    return http.$p("/mgmt/user/detail", param);
  },
  userAdd: (param) => {
    return http.$p("/mgmt/user/add", param);
  },
  userModify: (param) => {
    return http.$p("/mgmt/user/modify", param);
  },
  userModInfo: (param) => {
    return http.$p("/mgmt/user/mod-info", param);
  },
  userChgStat: (param) => {
    return http.$p("/mgmt/user/chg-stat", param);
  },
  userDelete: (param) => {
    return http.$p("/mgmt/user/delete", param);
  },
  userListRoles: (param) => {
    return http.$p("/mgmt/user/list-roles", param);
  },
  userBindRoles: (param) => {
    return http.$p("/mgmt/user/bind-roles", param);
  },

  roleListCurrTenant: () => {
    return http.$p("/role/list-curr-tenant");
  },
  roleListToBind: (param) => {
    return http.$p("/role/list-to-bind", param);
  },
};
