import http from "@/utils/http/index.js";

export default {
  companyList: (param) => {
    return http.$p("/company/list", param);
  },

  companyPage: (param) => {
    return http.$p("/company/page", param);
  },
  companyDetail: (param) => {
    return http.$p("/company/detail", param);
  },
  companyAdd: (param) => {
    return http.$p("/company/add", param);
  },
  companyModify: (param) => {
    return http.$p("/company/modify", param);
  },
  companyChgStat: (param) => {
    return http.$p("/company/chg-stat", param);
  },
  companyDelete: (param) => {
    return http.$p("/company/delete", param);
  },
  companyAssignSubsidiaries: (param) => {
    return http.$p("/company/assign-subsidiaries", param);
  },

  productList: () => {
    return http.$p("/product/list");
  },
  productListWithAgencies: () => {
    return http.$p("/product/list-with-agencies");
  },
  productListWithSubsidiaries: () => {
    return http.$p("/product/list-with-subsidiaries");
  },

  uploadLogo: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/logo/upload-images", param, headers);
  },
};
