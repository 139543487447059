import http from "@/utils/http/index.js";

export default {
  operatorPage: (param) => {
    return http.$p("/sys-operator/page", param);
  },
  operatorDetail: (param) => {
    return http.$p("/sys-operator/detail", param);
  },
  operatorAdd: (param) => {
    return http.$p("/sys-operator/add", param);
  },
  operatorModInfo: (param) => {
    return http.$p("/sys-operator/mod-info", param);
  },
  operatorChgStat: (param) => {
    return http.$p("/sys-operator/chg-stat", param);
  },
  operatorRstPw: (param) => {
    return http.$p("/sys-operator/rst-pw", param);
  },
  operatorDelete: (param) => {
    return http.$p("/sys-operator/delete", param);
  },
};
