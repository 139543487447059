export default {
  app: {
    home: "网站首页",
    prediction: "赛事預測",
    dataAnalysis: "數據分析",
    login: "登录",
    joinUs: "成為會員",
    trial: "試用",
  },
  homeView: {
    declaration: "本網站及其應用程式所有內容純屬資訊性質，並不涉及任何金錢賭博，亦不得作為此等意見的依據。詳情請參閱使用",
    rules: "使用條款及細則",
    privacyPolicy: "隱私政策聲明",
    companyName: "氪騰科技有限公司",
  },
  predictionView: {},
  dataAnalysisView: {},
};
