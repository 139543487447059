const dmUploadRecordMessages = Object.freeze({
  U1: "Failed to Upload.",
  E1: "Duplicate dates found within the uploaded file.",
  E2: "Missing values found in “Date” column.",
  E3: "Missing values found in the target column.",
  E4: "Wrong data type found.",
  E5: "There is a missing column/variable.",
  E6: "No data",
  E7: "No date column",
  E8: "Date formatting error",
  E9: "The date column must have only one",
  IS1: "Please try to fix these errors and upload again, or contact mApe for further assistance. Thank you.",
  W1: "Duplicate date found in the existing data.",
  W2: "Missing values detected.",
  W3: "Outliers detected.",
  W4: "User accepted the warnings.",
  W5: "User rejected the warnings.",
  U2: "Data has been uploaded successfully.",
  U3: "Data has not been uploaded.",
});

export default Object.freeze({
  landingPath: "/landing-page/subscription",

  dmUploadRecordMessages: dmUploadRecordMessages,
});
