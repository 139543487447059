import http from "@/utils/http/index.js";

export default {
  dataSourceList: () => {
    return http.$p("/data-source/list", {});
  },
  dataSourceRefresh: (param) => {
    return http.$p("/data-source/refresh", param);
  },
  dataSourceDetail: (param) => {
    return http.$p("/data-source/detail", param);
  },
  dataSourceModify: (param) => {
    return http.$p("/data-source/modify", param);
  },
  dataSourcePause: (param) => {
    return http.$p("/data-source/pause", param);
  },
  dataSourceCheck: (param) => {
    return http.$p("/data-source/check", param);
  },
  dataSourceDelete: (param) => {
    return http.$p("/data-source/delete", param);
  },
  dataSourceAdd: (param) => {
    return http.$p("/data-source/add", param);
  },
};
